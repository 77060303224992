import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Header, {
      ref: "header",
      activeRoute: $setup.nowRoute,
      scrollTurn: $setup.scrollTurn,
      onUpdateScroll: $setup.updateScroll
    }, null, 8, ["activeRoute", "scrollTurn", "onUpdateScroll"]),
    _createVNode(_component_router_view),
    _createVNode(_component_Footer, { onUpdateScroll: $setup.updateScroll }, null, 8, ["onUpdateScroll"])
  ]))
}