import { createApp } from "vue";
import App from "./App.vue";
import router from "./router/index";
import store from "./store";
import "@/styles/index.scss";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import "element-plus/theme-chalk/dark/css-vars.css";
import $utils from "@/utils/index";
import allApi from "@/api/apiAll.js";
import message from "@/utils/message/index";
const app = createApp(App);
app.config.globalProperties.$api = allApi;
app.config.globalProperties.$utils = $utils;
app.config.globalProperties.$message = message;
app.use(store).use(ElementPlus).use(router).mount("#app");
