import axios from 'axios'
import qs from 'qs'
import apiBaseUrl from './apiBaseUrl.js'; //基本路径
// import { storageSession,storageLocal } from "/@/utils/storage";
import message from "@/utils/message/index";
//创建axios实例
const service = axios.create({
  baseURL: apiBaseUrl,
  timeout: 10000
})

// 请求拦截器
service.interceptors.request.use(config => {

  for (const key in config.data) {
    if (!config.data[key] && config.data[key] !== 0) {
      delete config.data[key]
    }
  }
  config.data = qs.stringify(config.data);//转换为formData数据类型

  return config;
}, error => {
  return Promise.reject(error);
});

//响应拦截器
service.interceptors.response.use(response => {
  // console.log('response', response);
  //后端返回的状态码:1成功 0失败
  if (response.data.code == 401) {
    message.alert(response.data.msg);
    throw new Error(response.data.msg);
  } else if (response.data.code === 0) {
    message.alert(response.data.msg);
    throw new Error(response.data.msg);
  } else if (response.data.code !== 1) {
    message.alert(response.config.url + '==>' + response.data.msg);
    throw new Error(response.data.msg);
  }
  return response.data;
}, error => {
  if (error && error.response && error.response.status) {
    if (error.response.status) {
      // HTTP状态码
      switch (error.response.status) {
        case 400: error.message = '请求错误(400)';
          break;
        case 401: error.message = '未授权，请重新登录(401)';
          break;
        case 403: error.message = '拒绝访问(403)';
          break;
        case 404: error.message = '请求出错(404)';
          break;
        case 408: error.message = '请求超时(408)';
          break;
        case 500: error.message = '服务器错误(500)';
          break;
        case 501: error.message = '服务未实现(501)';
          break;
        case 502: error.message = '网络错误(502)';
          break;
        case 503: error.message = '服务不可用(503)';
          break;
        case 504: error.message = '网络超时(504)';
          break;
        case 505: error.message = 'HTTP版本不受支持(505)';
          break;
        default: error.message = '网络超时,请稍后重试';
      }
    }
  } else {
    error.message = '网络异常,请稍后重试';
  }
  //提示
  message.alert(error.message)
  return Promise.reject(error);
})

export default service
