import {
  createRouter,
  createWebHistory,
  RouteRecordRaw,
  createWebHashHistory,
} from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/home",
    meta: {
      title: "优才背调",
      showLink: true,
      keepAlive: true,
    },
  },
  {
    path: "/home",
    name: "home",
    component: () => import("@/views/home.vue"),
    meta: {
      title: "优才背调",
      showLink: true,
      keepAlive: true,
    },
  },
  {
    path: "/flowPath",
    name: "flowPath",
    component: () => import("@/views/flowPath/index.vue"),
    meta: {
      title: "背调流程",
      showLink: true,
      keepAlive: true,
    },
  },
  {
    path: "/surveyDetails",
    name: "surveyDetails",
    component: () => import("@/views/surveyDetails/index.vue"),
    meta: {
      title: "背调内容",
      showLink: true,
      keepAlive: true,
    },
  },
  {
    path: "/competitionAgree",
    name: "competitionAgree",
    component: () => import("@/views/competitionAgree/index.vue"),
    meta: {
      title: "优才背调",
      showLink: true,
      keepAlive: true,
    },
  },
  {
    path: "/aboutUs",
    name: "aboutUs",
    component: () => import("@/views/aboutUs/index.vue"),
    meta: {
      title: "关于我们",
      showLink: true,
      keepAlive: true,
    },
  },
  {
    path: "/scheme",
    name: "scheme",
    component: () => import("@/views/scheme/index.vue"),
    meta: {
      title: "解决方案",
      showLink: true,
      keepAlive: true,
    },
  },
  {
    path: "/apiServe",
    name: "apiServe",
    component: () => import("@/views/apiServe/index.vue"),
    meta: {
      title: "API服务",
      showLink: true,
      keepAlive: true,
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, _from, next) => {
  if (typeof to.meta?.title === "string") {
    document.title = to.meta.title;
  }
  next((that) => {
    // window.scrollTo({ top: 0 });
  });
});
export default router;
