import request from '@/request/request.js'

const allApi = {
    /**
     * 联系我们
       */
    contactUs(data) {
        return request({
            url: "common/contactUs",
            method: "POST",
            data
        })
    },
    sendMobileCode(data) {
        return request({
            url: "common/sendMobileCode",
            method: "POST",
            data
        })
    },
};


// 返回在vue模板中的调用接口
export default allApi;
