import { ElMessage, ElMessageBox } from "element-plus";

const messages = {
  // 消息
  Message: (message: string): any => {
    return ElMessage({
      showClose: true,
      offset: 120,
      duration: 2000,
      message,
    });
  },

  // 成功
  successMessage: (message: string): any => {
    return ElMessage({
      showClose: true,
      duration: 2000,
      offset: 120,
      message,
      type: "success",
    });
  },

  // 警告
  warnMessage: (message: string): any => {
    return ElMessage({
      showClose: true,
      duration: 2000,
      offset: 120,
      message,
      type: "warning",
      center: true,
    });
  },

  // 失败
  errorMessage: (message: string): any => {
    return ElMessage({
      showClose: true,
      duration: 2000,
      offset: 120,
      message,
      type: "error",
    });
  },

  alert: (
    message: string,
    title: string,
    callBack: (state) => void,
    configText: any
  ) => {
    ElMessageBox.alert(message, title || "提示", {
      confirmButtonText: configText || "确定",
      callback: (action) => {
        callBack instanceof Function && callBack("1");
      },
      center: true,
    });
  },

  confirmMessage: (
    message: string,
    callBack: (state) => void,
    title: string,
    type: any,
    configText: any,
    cancelText: any
  ): any => {
    ElMessageBox.confirm(message, title || "温馨提示", {
      confirmButtonText: configText || "确定",
      cancelButtonText: cancelText || "取消",
      type: type || "warning",
    })
      .then(() => {
        callBack instanceof Function && callBack("1");
      })
      .catch(() => {
        callBack instanceof Function && callBack("0");
      });
  },
};

export default messages;
