
import { onMounted, onBeforeUnmount, ref, watch, computed, nextTick, getCurrentInstance } from "vue";
import { useRoute, useRouter } from "vue-router";
import Header from "@/components/header.vue"; // @ is an alias to /src
import Footer from "@/components/footer.vue"; // @ is an alias to /src
const route = useRoute();
const router = useRouter();

export default {
  name: "app",
  components: {
    Header,
    Footer,
  },
  setup(props) {
    const { proxy } = getCurrentInstance() as any;

    let scrollTop = ref<number>(0); // 记录当前的滚动距离

    let header = ref(null);
    let fixedTurn = ref(false);
    let offsetHeight = ref(document.getElementById("homeBanner")?.offsetHeight || 0);
    let absOffsetHeight = ref(document.getElementById("homeBanner")?.offsetHeight || 0);

    let nowRoute = ref(location.hash.split("?")[0].split("/")[location.hash.split("?")[0].split("/").length - 1]);
    if (scrollTop.value >= 100) {
      nowRoute.value = "other";
    } else if (route && route.path) {
      nowRoute.value = route.path.split("/")[1];
    }
    let scrollTurn = computed(() => {
      return scrollTop.value > 0;
    });
    let pathName = computed(() => {
      return location.hash.split("?")[0].split("/")[location.hash.split("?")[0].split("/").length - 1];
    });
    function handleScroll() {
      window.addEventListener("scroll", () => {
        scrollTop.value = window.scrollY;
      });
    }

    let updateScroll = (res) => {
      nextTick(() => {
        scrollTop.value = window.scrollY;
        nowRoute.value = location.hash.split("?")[0].split("/")[location.hash.split("?")[0].split("/").length - 1];
        if (res == 1) {
          setTimeout(() => {
            nowRoute.value = location.hash.split("?")[0].split("/")[location.hash.split("?")[0].split("/").length - 1];
          }, 80);
        }
      });
    };
    nextTick(() => {
      watch(
        () => scrollTop.value,
        (newValue, oldValue) => {
          updateScroll(0);
        }
      );
    });
    onMounted(() => {
      handleScroll();
      nowRoute.value = location.hash.split("?")[0].split("/")[location.hash.split("?")[0].split("/").length - 1];
    });
    onBeforeUnmount(() => {
      window.scrollTo({
        top: 0,
      });
      window.removeEventListener("scroll", () => null); // 离开当前组件别忘记移除事件监听
    });
    return { scrollTop, nowRoute, updateScroll, fixedTurn, scrollTurn, pathName, header, offsetHeight, absOffsetHeight };
  },
};
